
export const modules = [
  "PPE Module",
  "Emergency Exit",
  "Machine Guard",
  "Forklift",
];
export const areas = ["Warehouse", "Distribution", "Production"];
export const shifts = ["Shift 1", "Shift 2", "Shift 3"];
export const weeks = ["Week 1", "Week 2", "Week 3", "Week 4"];
export const factories = ["ICF Factory", "Foods Factory", "RYK Factory"];
export const modulesforAlerts = [
  "Helmet",
  "Vest",
  "Emergency Exit",
  "Machine Guard",
  "MMHE",
];
export const modulesForRefrigeration = [
  "Helmet Module",
  "Emergency Exit",
  "Machine Guard",
];
export const statuses = ["Active", "In Active"];
export const ViolationSeverity = ["High", "Medium plus", "Medium"];
export const Shifts = ["Shift A", "Shift B", "Shift C"];
export const DateFilters = ["Daily", "Weekly", "Monthly", "Custom"];
export const CamersStatus = ["Active", "InActive"];
export const Modules = ['Helmet', 'Vest', 'Emergency Exit', 'MMHE', 'Machine Guard']
export const CameraAddOptions= ["Individual", "Excel"];
export const ReductionTarget = ["-10%", "-15%", "-20%", "-25%", "-30%", "-35%", "-40%", "-50%", "-60%", "-70%", "-80%", "-90%", "-100%"];

export const subareaOptionsData = {
  "AO-1": [
    "Roof Tops (Palletizing)",
    "Palletizing 1,2 & 3",
    "palletizing office",
    "palletizing corridor",
    "waste window area",
  ],
  "AO-2": [
    "Roof Tops (Service Building)",
    "Ammonia Compressor room",
    "Catch pit area",
    "Ref workshop",
    "Ref Control Room",
    "Ammonia CCR",
    "Diffusion tank",
  ],
  "AO-3": [
    "Void Area (Production, Mixing)",
    "Admin Building Roof Top",
    "AHU Room above Canteen",
    "Main Asset scrap yard",
    "motor / panel scrap yard",
    "R&D front side scrap yard",
    "Contractor Workshops",
    "DP store area",
    "engineering store",
    "safety office",
    "safety storage area",
    "engineering store placement yard",
    "Fabrication workshop & surrounding area",
    "Lathe Machine Workshop",
    "MAMz workshop",
  ],
  "AO-4": [
    "Roof Tops (Cone Baking)",
    "Cone Baking",
    "Mixing",
    "LI room",
    "aging room",
    "chocolate plant",
    "mixing pits",
    "oil/glucose decanting area",
    "sauce plant",
    "chilled room",
    "day store area",
    "mixing control room",
    "tank form",
  ],
  "AO-5": ["OHC", "Medical Roof Top"],
  "AO-6": [
    "Roof Tops (Dry Store)",
    "Roof Tops (Pulp Store)",
    "Scrap Yard (Packmat area/ drums)",
    "Dry Store 1, 2",
    "chemical store",
    "dry store driver room",
    "docking stations",
    "washrooms",
    "Pulp Store",
    "Hot room",
    "flavour room",
    "Pallet washing room",
  ],
  "AO-7": [
    "Machine Parts Room",
    "Ultra Clean",
    "Production floor",
    "production offices",
    "TPM room",
    "Day store",
    "Parts room",
    "room 10",
    "OPC chemical room",
  ],
  "AO-8": [
    "ETP",
    "Boiler",
    "Air compressor",
    "boiler control room",
    "HFO tank",
    "water filter area",
  ],
  "AO-9": [
    "Roof Tops (Canteen)",
    "Roof Tops (Security)",
    "Time Office",
    "ETMS",
    "Medical OHC",
    "Security Office",
    "Parkings",
    "Cycle Stand",
    "Smoking Area",
    "Area between Multan road gate to inner factory entrance gate",
    "Admin Building",
    "reception",
    "canteen",
    "kitchen",
    "galleries",
    "washrooms",
    "locker area",
    "masjid",
    "changing rooms",
    "waiting area",
    "girls room",
    "exit routes",
    "brains lab",
    "recharge room",
    "Humail's office",
    "meeting rooms",
    "IT room",
    "Outside Taris",
  ],
  "AO-10": [
    "Solar Area (Panels, Transformer rooms & entire area)",
    "Diesel Storage area",
    "earth pit area",
    "electrical power house",
    "LT room",
    "HT room",
    "gen set area",
    "transformer room",
    "ammonia soft starter room",
  ],
  "AO-11": ["R&D Innovation Centre (Complete)"],
  "AO-12": ["QA", "Pathogen Lab", "QA storeroom"],
  "AO-13": [
    "LPG Area",
    "Pump House",
    "Water treatment plant & roof",
    "Biomass Boiler (including fuel storage shed)",
  ],
  "AO-14": [
    "Roof Tops (Cold Stores)",
    "Wooden Pallets Area",
    "FG BOF",
    "Cold Store 1&2",
    "Cold store offices",
    "Dispatch area",
  ],
  "AO-15": ["UE Projects", "Projects Store"],
};

export const areaOptionsData = [
  "AO-1",
  "AO-2",
  "AO-3",
  "AO-4",
  "AO-5",
  "AO-6",
  "AO-7",
  "AO-8",
  "AO-9",
  "AO-10",
  "AO-11",
  "AO-12",
  "AO-13",
  "AO-14",
  "AO-15",
];

// export const dummyDataForLiveAlerts = [
//     {
//       sr: 1,
//       VioSeverity: "Low",
//       module: "Helmet",
//       violation: "No Helmet",
//       violationArea: "AO-1",
//       areaOwner: 'Adil',
//       subArea: 'palletizing office',
//       date: "2024-10-08",
//       time: "02:35 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 2,
//       VioSeverity: "Low",
//       module: "MMHE",
//       violation: "No Vest",
//       violationArea: "AO-1",
//       areaOwner: 'Adil',
//       subArea: 'Ammonia Compressor room',
//       date: "2024-10-08",
//       time: "12:03 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 3,
//       VioSeverity: "High",
//       module: "Emergency Exit",
//       violation: "No Vest",
//       violationArea: "AO-2",
//       areaOwner: 'Altaf',
//       date: "2024-10-08",
//       time: "11:03 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 4,
//       VioSeverity: "Low",
//       module: "Emergency Exit",
//       violation: "No Helmet",
//       violationArea: "1",
//       date: "2024-10-08",
//       time: "09:31 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 5,
//       VioSeverity: "High",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "3",
//       date: "2024-10-07",
//       time: "01:18 PM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 6,
//       VioSeverity: "High",
//       module: "Emergency Exit",
//       violation: "MMME Violation",
//       violationArea: "1",
//       date: "2024-10-07",
//       time: "02:48 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 7,
//       VioSeverity: "Medium",
//       module: "Emergency Exit",
//       violation: "MMME Violation",
//       violationArea: "3",
//       date: "2024-10-07",
//       time: "05:38 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 8,
//       VioSeverity: "Low",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "1",
//       date: "2024-10-07",
//       time: "01:58 PM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 9,
//       VioSeverity: "Low",
//       module: "Emergency Exit",
//       violation: "No Helmet",
//       violationArea: "3",
//       date: "2024-10-07",
//       time: "07:24 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 10,
//       VioSeverity: "High",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "1",
//       date: "2024-10-07",
//       time: "03:19 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 11,
//       VioSeverity: "Low",
//       module: "Helmet",
//       violation: "No Helmet",
//       violationArea: "1",
//       date: "2024-10-06",
//       time: "12:45 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 12,
//       VioSeverity: "High",
//       module: "Vest",
//       violation: "MMME Violation",
//       violationArea: "3",
//       date: "2024-10-06",
//       time: "01:30 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 13,
//       VioSeverity: "Medium",
//       module: "Emergency Exit",
//       violation: "Exit Door Blocked",
//       violationArea: "5",
//       date: "2024-10-06",
//       time: "02:15 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 14,
//       VioSeverity: "Low",
//       module: "Machine Guard",
//       violation: "Guard Missing",
//       violationArea: "2",
//       date: "2024-10-06",
//       time: "07:55 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 15,
//       VioSeverity: "Medium",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "4",
//       date: "2024-10-06",
//       time: "08:10 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 16,
//       VioSeverity: "High",
//       module: "Helmet",
//       violation: "MMME Violation",
//       violationArea: "1",
//       date: "2024-10-06",
//       time: "08:45 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 17,
//       VioSeverity: "Low",
//       module: "Vest",
//       violation: "No Helmet",
//       violationArea: "3",
//       date: "2024-10-06",
//       time: "09:30 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 18,
//       VioSeverity: "Medium",
//       module: "Emergency Exit",
//       violation: "Exit Door Blocked",
//       violationArea: "2",
//       date: "2024-10-06",
//       time: "11:15 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 19,
//       VioSeverity: "High",
//       module: "MMHE",
//       violation: "Guard Missing",
//       violationArea: "5",
//       date: "2024-10-06",
//       time: "01:50 PM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 20,
//       VioSeverity: "Low",
//       module: "Machine Guard",
//       violation: "No Helmet",
//       violationArea: "4",
//       date: "2024-10-06",
//       time: "02:20 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 21,
//       VioSeverity: "Medium",
//       module: "Helmet",
//       violation: "MMME Violation",
//       violationArea: "3",
//       date: "2024-10-06",
//       time: "02:40 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 22,
//       VioSeverity: "High",
//       module: "Vest",
//       violation: "Exit Door Blocked",
//       violationArea: "2",
//       date: "2024-10-06",
//       time: "03:15 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 23,
//       VioSeverity: "Low",
//       module: "Emergency Exit",
//       violation: "Guard Missing",
//       violationArea: "1",
//       date: "2024-10-06",
//       time: "05:05 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 24,
//       VioSeverity: "Medium",
//       module: "Machine Guard",
//       violation: "No Helmet",
//       violationArea: "5",
//       date: "2024-10-06",
//       time: "06:30 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 25,
//       VioSeverity: "High",
//       module: "MMHE",
//       violation: "MMME Violation",
//       violationArea: "4",
//       date: "2024-10-06",
//       time: "08:45 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 26,
//       VioSeverity: "Low",
//       module: "Helmet",
//       violation: "No Helmet",
//       violationArea: "1",
//       date: "2024-10-05",
//       time: "01:10 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 27,
//       VioSeverity: "High",
//       module: "Vest",
//       violation: "MMME Violation",
//       violationArea: "3",
//       date: "2024-10-05",
//       time: "01:55 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 28,
//       VioSeverity: "Medium",
//       module: "Emergency Exit",
//       violation: "Exit Door Blocked",
//       violationArea: "2",
//       date: "2024-10-05",
//       time: "06:30 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 29,
//       VioSeverity: "Low",
//       module: "Machine Guard",
//       violation: "Guard Missing",
//       violationArea: "4",
//       date: "2024-10-05",
//       time: "07:00 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 30,
//       VioSeverity: "Medium",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "1",
//       date: "2024-10-05",
//       time: "08:15 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 31,
//       VioSeverity: "High",
//       module: "Helmet",
//       violation: "MMME Violation",
//       violationArea: "2",
//       date: "2024-10-05",
//       time: "09:30 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 32,
//       VioSeverity: "Low",
//       module: "Vest",
//       violation: "No Helmet",
//       violationArea: "3",
//       date: "2024-10-05",
//       time: "10:45 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 33,
//       VioSeverity: "Medium",
//       module: "Emergency Exit",
//       violation: "Exit Door Blocked",
//       violationArea: "4",
//       date: "2024-10-05",
//       time: "01:20 PM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 34,
//       VioSeverity: "High",
//       module: "MMHE",
//       violation: "Guard Missing",
//       violationArea: "1",
//       date: "2024-10-05",
//       time: "03:30 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 35,
//       VioSeverity: "Low",
//       module: "Machine Guard",
//       violation: "No Helmet",
//       violationArea: "5",
//       date: "2024-10-05",
//       time: "05:00 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 36,
//       VioSeverity: "Medium",
//       module: "Helmet",
//       violation: "No Helmet",
//       violationArea: "2",
//       date: "2024-10-04",
//       time: "12:30 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 37,
//       VioSeverity: "High",
//       module: "Vest",
//       violation: "MMME Violation",
//       violationArea: "3",
//       date: "2024-10-04",
//       time: "01:15 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 38,
//       VioSeverity: "Low",
//       module: "Emergency Exit",
//       violation: "Exit Door Blocked",
//       violationArea: "1",
//       date: "2024-10-04",
//       time: "05:50 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 39,
//       VioSeverity: "Medium",
//       module: "Machine Guard",
//       violation: "Guard Missing",
//       violationArea: "2",
//       date: "2024-10-04",
//       time: "07:10 AM",
//       factory: "ICF",
//       shift: "Shift A",
//     },
//     {
//       sr: 40,
//       VioSeverity: "High",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "4",
//       date: "2024-10-04",
//       time: "08:20 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 41,
//       VioSeverity: "Low",
//       module: "Helmet",
//       violation: "MMME Violation",
//       violationArea: "3",
//       date: "2024-10-04",
//       time: "09:40 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 42,
//       VioSeverity: "Medium",
//       module: "Vest",
//       violation: "No Helmet",
//       violationArea: "5",
//       date: "2024-10-04",
//       time: "11:25 AM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 43,
//       VioSeverity: "High",
//       module: "Emergency Exit",
//       violation: "Exit Door Blocked",
//       violationArea: "2",
//       date: "2024-10-04",
//       time: "02:15 PM",
//       factory: "ICF",
//       shift: "Shift B",
//     },
//     {
//       sr: 44,
//       VioSeverity: "Low",
//       module: "Machine Guard",
//       violation: "Guard Missing",
//       violationArea: "1",
//       date: "2024-10-04",
//       time: "03:50 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//     {
//       sr: 45,
//       VioSeverity: "Medium",
//       module: "MMHE",
//       violation: "No Helmet",
//       violationArea: "4",
//       date: "2024-10-04",
//       time: "07:30 PM",
//       factory: "ICF",
//       shift: "Shift C",
//     },
//   ];

export const dummyDataForLiveAlerts = [
  {
    sr: 1,
    VioSeverity: "Low",
    module: "Helmet",
    violation: "No Helmet",
    violationArea: "AO-1",
    areaOwner: "Adil",
    subArea: "palletizing office",
    date: "2024-10-08",
    time: "02:35 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 2,
    VioSeverity: "Low",
    module: "MMHE",
    violation: "No Vest",
    violationArea: "AO-1",
    areaOwner: "Adil",
    subArea: "Ammonia Compressor room",
    date: "2024-10-08",
    time: "12:03 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 3,
    VioSeverity: "High",
    module: "Emergency Exit",
    violation: "No Vest",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Ammonia Compressor room",
    date: "2024-10-08",
    time: "11:03 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 4,
    VioSeverity: "Low",
    module: "Emergency Exit",
    violation: "No Helmet",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Ref workshop",
    date: "2024-10-08",
    time: "09:31 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 5,
    VioSeverity: "High",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-3",
    areaOwner: "Arslan",
    subArea: "motor / panel scrap yard",
    date: "2024-10-07",
    time: "01:18 PM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 6,
    VioSeverity: "High",
    module: "Emergency Exit",
    violation: "MMME Violation",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "Cone Baking",
    date: "2024-10-07",
    time: "02:48 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 7,
    VioSeverity: "Medium",
    module: "Emergency Exit",
    violation: "MMME Violation",
    violationArea: "AO-5",
    areaOwner: "Dr. Amjad",
    subArea: "Medical Roof Top",
    date: "2024-10-07",
    time: "05:38 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 8,
    VioSeverity: "Low",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-6",
    areaOwner: "Meraj",
    subArea: "Dry Store 1, 2",
    date: "2024-10-07",
    time: "01:58 PM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 9,
    VioSeverity: "Low",
    module: "Emergency Exit",
    violation: "No Helmet",
    violationArea: "AO-7",
    areaOwner: "Moazzam Ali",
    subArea: "Production floor",
    date: "2024-10-07",
    time: "07:24 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 10,
    VioSeverity: "High",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-8",
    areaOwner: "Muhammad Shahbaz",
    subArea: "Boiler",
    date: "2024-10-07",
    time: "03:19 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 11,
    VioSeverity: "Low",
    module: "Helmet",
    violation: "No Helmet",
    violationArea: "AO-9",
    areaOwner: "Muhammad Wasi",
    subArea: "Medical OHC",
    date: "2024-10-06",
    time: "12:45 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 12,
    VioSeverity: "High",
    module: "Vest",
    violation: "MMME Violation",
    violationArea: "AO-10",
    areaOwner: "Nazir Sb",
    subArea: "Solar Area (Panels, Transformer rooms)",
    date: "2024-10-06",
    time: "01:30 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 13,
    VioSeverity: "Medium",
    module: "Emergency Exit",
    violation: "Exit Door Blocked",
    violationArea: "AO-11",
    areaOwner: "Sadia",
    subArea: "R&D Innovation Centre",
    date: "2024-10-06",
    time: "02:15 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 14,
    VioSeverity: "Low",
    module: "Machine Guard",
    violation: "Guard Missing",
    violationArea: "AO-12",
    areaOwner: "Shafiq",
    subArea: "Pathogen Lab",
    date: "2024-10-06",
    time: "07:55 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 15,
    VioSeverity: "Medium",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-13",
    areaOwner: "Shahbaz",
    subArea: "Pump House",
    date: "2024-10-06",
    time: "08:10 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 16,
    VioSeverity: "High",
    module: "Helmet",
    violation: "MMME Violation",
    violationArea: "AO-14",
    areaOwner: "Sheraz",
    subArea: "Wooden Pallets Area",
    date: "2024-10-06",
    time: "08:45 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 17,
    VioSeverity: "Low",
    module: "Vest",
    violation: "No Helmet",
    violationArea: "AO-15",
    areaOwner: "Umair Pervaiz",
    subArea: "Projects Store",
    date: "2024-10-06",
    time: "09:30 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 18,
    VioSeverity: "Medium",
    module: "Emergency Exit",
    violation: "Exit Door Blocked",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Ammonia Compressor room",
    date: "2024-10-06",
    time: "11:15 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 19,
    VioSeverity: "High",
    module: "MMHE",
    violation: "Guard Missing",
    violationArea: "AO-5",
    areaOwner: "Dr. Amjad",
    subArea: "Medical Roof Top",
    date: "2024-10-06",
    time: "01:50 PM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 20,
    VioSeverity: "Low",
    module: "Machine Guard",
    violation: "No Helmet",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "Mixing",
    date: "2024-10-06",
    time: "02:20 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 21,
    VioSeverity: "Medium",
    module: "Helmet",
    violation: "MMME Violation",
    violationArea: "AO-3",
    areaOwner: "Arslan",
    subArea: "motor / panel scrap yard",
    date: "2024-10-06",
    time: "02:40 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 22,
    VioSeverity: "High",
    module: "Vest",
    violation: "Exit Door Blocked",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "LI room",
    date: "2024-10-06",
    time: "03:15 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 23,
    VioSeverity: "Low",
    module: "Emergency Exit",
    violation: "Guard Missing",
    violationArea: "AO-7",
    areaOwner: "Moazzam Ali",
    subArea: "Parts room",
    date: "2024-10-06",
    time: "05:05 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 24,
    VioSeverity: "Medium",
    module: "Machine Guard",
    violation: "No Helmet",
    violationArea: "AO-6",
    areaOwner: "Meraj",
    subArea: "Dry Store 1, 2",
    date: "2024-10-06",
    time: "06:30 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 25,
    VioSeverity: "High",
    module: "MMHE",
    violation: "MMME Violation",
    violationArea: "AO-5",
    areaOwner: "Dr. Amjad",
    subArea: "Medical Roof Top",
    date: "2024-10-06",
    time: "08:45 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 26,
    VioSeverity: "Low",
    module: "Helmet",
    violation: "No Helmet",
    violationArea: "AO-1",
    areaOwner: "Adil",
    subArea: "palletizing office",
    date: "2024-10-05",
    time: "01:10 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 27,
    VioSeverity: "High",
    module: "Vest",
    violation: "MMME Violation",
    violationArea: "AO-3",
    areaOwner: "Arslan",
    subArea: "R&D front side scrap yard",
    date: "2024-10-05",
    time: "01:55 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 28,
    VioSeverity: "Medium",
    module: "Emergency Exit",
    violation: "Exit Door Blocked",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Catch pit area",
    date: "2024-10-05",
    time: "06:30 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 29,
    VioSeverity: "Low",
    module: "Machine Guard",
    violation: "Guard Missing",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "oil/glucose decanting area",
    date: "2024-10-05",
    time: "07:00 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 30,
    VioSeverity: "Medium",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-1",
    areaOwner: "Adil",
    subArea: "palletizing corridor",
    date: "2024-10-05",
    time: "08:15 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 31,
    VioSeverity: "High",
    module: "Helmet",
    violation: "MMME Violation",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Ref Control Room",
    date: "2024-10-05",
    time: "09:30 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 32,
    VioSeverity: "Low",
    module: "Vest",
    violation: "No Helmet",
    violationArea: "AO-3",
    areaOwner: "Arslan",
    subArea: "safety storage area",
    date: "2024-10-05",
    time: "10:45 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 33,
    VioSeverity: "Medium",
    module: "Emergency Exit",
    violation: "Exit Door Blocked",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "tank form",
    date: "2024-10-05",
    time: "01:20 PM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 34,
    VioSeverity: "High",
    module: "MMHE",
    violation: "Guard Missing",
    violationArea: "AO-7",
    areaOwner: "Moazzam Ali",
    subArea: "OPC chemical room",
    date: "2024-10-05",
    time: "03:30 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 35,
    VioSeverity: "Low",
    module: "Machine Guard",
    violation: "No Helmet",
    violationArea: "AO-6",
    areaOwner: "Meraj",
    subArea: "Pulp Store",
    date: "2024-10-05",
    time: "05:00 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 36,
    VioSeverity: "Medium",
    module: "Helmet",
    violation: "No Helmet",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Diffusion tank",
    date: "2024-10-04",
    time: "12:30 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 37,
    VioSeverity: "High",
    module: "Vest",
    violation: "MMME Violation",
    violationArea: "AO-3",
    areaOwner: "Arslan",
    subArea: "Contractor Workshops",
    date: "2024-10-04",
    time: "01:15 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 38,
    VioSeverity: "Low",
    module: "Emergency Exit",
    violation: "Exit Door Blocked",
    violationArea: "AO-1",
    areaOwner: "Adil",
    subArea: "palletizing corridor",
    date: "2024-10-04",
    time: "05:50 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 39,
    VioSeverity: "Medium",
    module: "Machine Guard",
    violation: "Guard Missing",
    violationArea: "AO-2",
    areaOwner: "Aftab",
    subArea: "Ammonia CCR",
    date: "2024-10-04",
    time: "07:10 AM",
    factory: "ICF",
    shift: "Shift A",
  },
  {
    sr: 40,
    VioSeverity: "High",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "day store area",
    date: "2024-10-04",
    time: "08:20 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 41,
    VioSeverity: "Low",
    module: "Helmet",
    violation: "MMME Violation",
    violationArea: "AO-3",
    areaOwner: "Arslan",
    subArea: "engineering store",
    date: "2024-10-04",
    time: "09:40 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 42,
    VioSeverity: "Medium",
    module: "Vest",
    violation: "No Helmet",
    violationArea: "AO-5",
    areaOwner: "Dr. Amjad",
    subArea: "Medical Roof Top",
    date: "2024-10-04",
    time: "11:25 AM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 43,
    VioSeverity: "High",
    module: "Emergency Exit",
    violation: "Exit Door Blocked",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "mixing pits",
    date: "2024-10-04",
    time: "02:15 PM",
    factory: "ICF",
    shift: "Shift B",
  },
  {
    sr: 44,
    VioSeverity: "Low",
    module: "Machine Guard",
    violation: "Guard Missing",
    violationArea: "AO-1",
    areaOwner: "Adil",
    subArea: "waste window area",
    date: "2024-10-04",
    time: "03:50 PM",
    factory: "ICF",
    shift: "Shift C",
  },
  {
    sr: 45,
    VioSeverity: "Medium",
    module: "MMHE",
    violation: "No Helmet",
    violationArea: "AO-4",
    areaOwner: "Ayesha Khaliq",
    subArea: "chocolate plant",
    date: "2024-10-04",
    time: "07:30 PM",
    factory: "ICF",
    shift: "Shift C",
  },
];

export const monthlyDataa = {
  series: [
    {
      name: "Total",
      data: [
        642, 864, 275, 623, 315, 718, 894, 644, 478, 857, 845, 710, 820, 410,
        620, 310, 740, 590, 660, 800, 420, 500, 600, 700, 800,
      ],
    },
    {
      name: "Helmet",
      data: [
        108, 199, 60, 80, 90, 100, 105, 223, 60, 210, 30, 150, 180, 120, 90,
        110, 130, 140, 120, 200, 100, 90, 80, 60, 40
      ],
    },
    {
      name: "Vest",
      data: [
        69, 210, 40, 100, 110, 98, 209, 90, 100, 290, 190, 150, 160, 170, 180,
        200, 220, 240, 260, 280, 300, 120, 140, 160, 180
      ],
    },
    {
      name: "Emergency Exit",
      data: [
        230, 240, 30, 218, 50, 210, 90, 80, 118, 100, 115, 160, 170, 180, 190,
        200, 210, 220, 230, 240, 250, 260, 270, 280, 290
      ],
    },
    {
      name: "Machine Guard",
      data: [
        20, 190, 110, 180, 10, 245, 223, 95, 105, 67, 200, 210, 220, 230, 240,
        250, 260, 270, 280, 290, 300, 40, 50, 60, 70

      ],
    },
    {
      name: "MMHE",
      data: [
        215, 25, 35, 45, 55, 65, 167, 156, 95, 190, 210, 220, 230, 240, 250,
        260, 270, 280, 290, 300, 310, 100, 110, 120, 130
      ],
    },
  ],
  categories: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",

  ],
};

export const weeklyData = {
  series: [
    { name: "Total", data: [120, 130, 150, 110, 90, 95, 130] },
    { name: "Helmet", data: [20, 30, 15, 18, 19, 21, 25] },
    { name: "Vest", data: [12, 14, 18, 15, 16, 17, 20] },
    { name: "Emergency Exit", data: [35, 40, 30, 25, 28, 32, 34] },
    { name: "Machine Guard", data: [25, 22, 20, 18, 30, 28, 35] },
    { name: "MMHE", data: [15, 18, 12, 10, 14, 17, 15] },
  ],
  categories: [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun"
  ],
};

export const shiftData = {
  series: [
    {
      name: "Total",
      data: [100, 120, 110, 130, 125, 115, 140, 150, 135, 145, 155, 160],
    },
    { name: "Helmet", data: [25, 30, 28, 35, 32, 30, 38, 40, 36, 42, 45, 50] },
    { name: "Vest", data: [18, 22, 20, 25, 23, 21, 27, 30, 29, 31, 35, 38] },
    {
      name: "Emergency Exit",
      data: [40, 45, 42, 50, 48, 44, 55, 60, 58, 65, 70, 75],
    },
    {
      name: "Machine Guard",
      data: [30, 35, 33, 40, 38, 36, 42, 45, 48, 50, 52, 55],
    },
    { name: "MMHE", data: [15, 18, 16, 20, 19, 17, 22, 25, 24, 26, 28, 30] },
  ],
  categories: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",

  ],
};





export const dummyJSON = JSON.stringify({
  complianceData: {
    series: [75],
  },
  week: "Week 42 Data",
  aiAccuracy: {
    value: 85,
    trend: "down", // down or up
    week: "Week 42 Data",
  },
  connectivity: {
    value: 100,
    trend: "up", // down or up
    status: "All systems operational",
  },
  highSeverityAlerts: {
    alerts: 20,
    module: "Helmet",
    camera: "Camera 01",
  },
  maxAlerts: {
    area: "AO-1",
    owner: "Aftab",
    subArea: "Catch pit Area",
  },
  progressData: [
    {
      progressId: 'pregress1',
      name: "Helmet",
      barValue: 90,
      alerts: 24,
      trend: "uptrend",
      percentage: "90%",
      barColor: "success",
      tooltipContent: [
        { label: 'Max alerts by', value: 'AO-1 ( Adil )' },
        { label: 'Sub Area', value: 'Palletizing corridor' },
        { label: 'Alerts', value: '10' }
      ]
    },


    {
      data: 'progress2',
      name: "Vest",
      barValue: 40,
      alerts: 20,
      trend: "downtrend",
      percentage: "40%",
      barColor: "danger",
      tooltipContent: [
        { label: 'Max alerts by', value: 'AO-2 ( Aftab )' },
        { label: 'Sub Area', value: 'Catch pit area' },
        { label: 'Alerts', value: '77' }
      ]
    },


    {
      data: 'progress3',
      name: "Emergency Exit",
      barValue: 50,
      alerts: 50,
      subArea: "OHC",
      trend: "uptrend",
      percentage: "50%",
      barColor: "primary",
      tooltipContent: [
        { label: 'Max alerts by', value: 'AO-3 ( Dr. Amjad )' },
        { label: 'Sub Area', value: 'OHC' },
        { label: 'Alerts', value: '50' }
      ]
    },


    {
      data: 'progress4',
      name: "Machine Guard",
      barValue: 60,
      alerts: 50,
      trend: "downtrend",
      percentage: "60%",
      barColor: "warning",
      tooltipContent: [
        { label: 'Max alerts by', value: 'AO-4 ( Arsalan )' },
        { label: 'Sub Area', value: 'DP store area' },
        { label: 'Alerts', value: '25' }
      ]
    },


    {
      data: 'progress5',
      name: "MMHE",
      barValue: 74,
      alerts: 50,
      trend: "uptrend",
      percentage: "74%",
      barColor: "info",
      tooltipContent: [
        { label: 'Max alerts by', value: 'AO-2 ( Aftab )' },
        { label: 'Sub Area', value: 'Catch pit area' },
        { label: 'Alerts', value: '14' }
      ]
    },

  ],


  // heatmapData: {
  //   areas: ['Helmet', 'Vest', 'E. Exit', 'Guard', 'MMHE'],
  //   subAreas: ['AO-1', 'AO-2', 'AO-3', 'AO-4', 'AO-5', 'AO-6', 'AO-7', 'AO-8', 'AO-9', 'AO-10', 'AO-11', 'AO-12', 'AO-13', 'AO-14', 'AO-15'],
  //   areaOwner: ['Adil', 'Aftab', 'Arsalan', 'Ayesha Khaliq', 'Dr. Amjad', 'Meraj', 'Moazzam Ali', 'Muhammd Shahbaz', 'Muhammd Wasi', 'Nazir', 'Sadia', 'Shafiq', 'Shahbaz', 'Sheraz', 'Umair Pervaiz'],
  //   data: [

  //     {
  //       name: 'Helmet',
  //       data: [5, 15, 25, 35, 45, 55, 65, 75, 85, 95, 90, 66, 83, 90, 60]
  //     },
  //     {
  //       name: 'Vest',
  //       data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 85, 83, 65, 75, 97, 60]
  //     },
  //     {
  //       name: 'E. Exit',
  //       data: [8, 18, 28, 38, 48, 58, 68, 78, 88, 98, 90, 30, 60, 83, 90]
  //     },
  //     {
  //       name: 'Guard',
  //       data: [2, 12, 22, 32, 42, 52, 62, 72, 82, 92, 60, 77, 90, 66, 83]
  //     },
  //     {
  //       name: 'MMHE',
  //       data: [3, 13, 23, 33, 43, 53, 63, 73, 83, 93, 85, 83, 91, 89, 99]
  //     }
  //   ]
  // },

  
  
  
    heatmapData: {
      areas: [
        "AO-10",
        "AO-12",
        "AO-13",
        "AO-14",
        "AO-2",
        "AO-4",
        "AO-6",
        "AO-7",
        "AO-8",
        "AO-9"
      ],
      subAreas: [
        "AO-10",
        "AO-12",
        "AO-13",
        "AO-14",
        "AO-2",
        "AO-4",
        "AO-6",
        "AO-7",
        "AO-8",
        "AO-9"
      ],
      areaOwner: [
        "Aftab",
        "Ayesha Khaliq",
        "Meraj",
        "Moazam Ali",
        "Muhammad Shahbaz",
        "Muhammad Wasi",
        "Nazir Sb",
        "Shafiq",
        "Shahbaz",
        "Sheraz"
      ],
      data: [
        {
          name: "Helmet",
          data: [
            129,
            59,
            349,
            1,
            18,
            1,
            21,
            0,
            12,
            0
          ]
        },
        {
          name: "Vest",
          data: [
            142,
            56,
            398,
            2,
            33,
            1,
            11,
            0,
            13,
            0
          ]
        },
        {
          name: "MMHE",
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            77,
            0,
            0,
            0
          ]
        }
      ]
    },
  alertsTrendChart: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
    series: [
      { name: "Total", data: [645, 864, 275, 623, 315, 718, 894, 644, 478, 857, 845]},
      { name: "Helmet", data: [108, 199, 60, 80, 90, 100, 105, 223, 60, 210, 30]},
      { name: "Vest", data: [69, 210, 40, 100, 110, 98, 209, 90, 100, 290, 190]},
      { name: "Emergency Exit", data: [230, 240, 30, 218, 50, 210, 90, 80, 118, 100, 115]},
      { name: "Machine Guard", data: [20, 190, 110, 180, 10, 245, 223, 95, 105, 67, 200]},
      { name: "MMHE", data: [215, 25, 35, 45, 55, 65, 167, 156, 95, 190, 210]}
    ]
  }
});


export const AreaOwnerAnlyticsJSON = JSON.stringify({
  OwnerName: 'Meraj',
  complianceData: {
    series: [75],
  },
  week: "Week 42 Data",
  aiAccuracy: {
    value: 75,
    trend: "down", // down or up
    week: "Week 42 Data",
  },
  connectivity: {
    value: 99,
    trend: "up", // down or up
    status: "All systems operational",
  },
  highSeverityAlerts: {
    alerts: 15,
    module: "Helmet",
    camera: "Camera 02",
  },
  progressData: [
    {
      progressId: 'pregress1',
      name: "Helmet",
      barValue: 90,
      alerts: 24,
      trend: "uptrend",
      percentage: "90%",
      tooltipContent: [
        { label: 'Max alerts by', value: 'DRY Store 1, 2' },
        { label: 'Alerts', value: '10' }
      ]
    },


    {
      data: 'progress2',
      name: "Vest",
      barValue: 40,
      alerts: 20,
      trend: "downtrend",
      percentage: "40%",
      tooltipContent: [
        { label: 'Max alerts by', value: 'DRY Store 1, 2' },
        { label: 'Alerts', value: '77' }
      ]
    },


    // {
    //   data: 'progress3',
    //   name: "Emergency Exit",
    //   barValue: 50,
    //   alerts: 50,
    //   trend: "uptrend",
    //   percentage: "50%",
    //   tooltipContent: [
    //     { label: 'Max alerts by', value: 'OHC' },
    //     { label: 'Alerts', value: '50' }
    //   ]
    // },


    // {
    //   data: 'progress4',
    //   name: "Machine Guard",
    //   barValue: 60,
    //   alerts: 50,
    //   trend: "downtrend",
    //   percentage: "60%",
    //   barColor: "warning",
    //   tooltipContent: [
    //     { label: 'Max alerts by', value: 'DRY Store 1, 2' },
    //     { label: 'Alerts', value: '25' }
    //   ]
    // },


    {
      data: 'progress5',
      name: "MMHE",
      barValue: 74,
      alerts: 50,
      trend: "uptrend",
      percentage: "74%",
      barColor: "info",
      tooltipContent: [
        { label: 'Max alerts by', value: 'DRY Store 1, 2' },
        { label: 'Alerts', value: '14' }
      ]
    },

  ],
  heatmapData: {
    areas: ['Helmet', 'Vest', 'E. Exit', 'Guard', 'MMHE'],
    cameras: ['Cam-01', 'Cam-02', 'Cam-03', 'Cam-04', 'Cam-05', 'Cam-06', 'Cam-07', 'Cam-08', 'Cam-09', 'Cam-10', 'Cam-11', 'Cam-12', 'Cam-13', 'Cam-14', 'Cam-15'],
    cameraNames: [
      "Dry store 2 Camera 1",
      "Dry store 2 Camera 2",
      "Dry store 2 Camera 3",
      "Dry store 2 Camera 4",
      "Dry store 1 Camera 1",
      "Dry store 1 Camera 2",
      "Dry store 1 Camera 3",
      "Dry store 1 Camera 4",
      "Dry store 1 Camera 5",
      "Dry store 1 Camera 6",
      "Dry store 1 Camera 7",
      "Dry store 2 Camera 6",
      "Dry store 2 Camera 5",
      "Dry store 2 Camera 7",
      "Dry store 2 Camera 8"
    ],
  
    data: [

      {
        name: 'Helmet',
        data: [5, 15, 25, 0, 1, 55, 65, 75, 85, 95, 90, 66, 83, 90, 60, 90, 66, 83, 90, 60, ]
      },
      {
        name: 'Vest',
        data: [10, 0, 30, 40, 0, 2, 70, 80, 90, 85, 83, 65, 75, 97, 60, 90, 66, 83, 90, 60,]
      },
      {
        name: 'MMHE',
        data: [3, 13, 0, 33, 43, 53, 3, 73, 83, 93, 85, 83, 91, 89, 99, 90, 66, 83, 90, 60,]
      }
    ]
  //   data: [
  //     {
  //         name: 'Helmet',
  //         data: [2, 4, 13, 17, 18, 20, 20, 24, 28, 36, 44, 47, 54, 56, 59, 66, 70, 70, 72, 77, 79, 80, 81, 85, 91]
  //     },
  //     {
  //         name: 'Vest',
  //         data: [6, 8, 9, 12, 27, 27, 29, 36, 39, 44, 46, 47, 53, 58, 69, 76, 76, 78, 81, 88, 88, 90, 90, 96, 98]
  //     },
  //     {
  //         name: 'MMHE',
  //         data: [5, 5, 7, 10, 13, 15, 19, 19, 21, 25, 31, 49, 54, 55, 58, 60, 76, 76, 80, 85, 88, 90, 93, 93, 100]
  //     }
  // ]
  },
  alertsTrendChart: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
    series: [
      { name: "Total", data: [645, 864, 275, 623, 315, 718, 894, 644, 478, 857, 845]},
      { name: "Helmet", data: [108, 199, 60, 80, 90, 100, 105, 223, 60, 210, 30]},
      { name: "Vest", data: [69, 210, 40, 100, 110, 98, 209, 90, 100, 290, 190]},
      { name: "MMHE", data: [215, 25, 35, 45, 55, 65, 167, 156, 95, 190, 210]}
    ]
  }
});



