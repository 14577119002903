import React from 'react'
import GlobalAllCamera from '../../../../Screens/GlobalUser/CameraConfigurations/cameras_table';

const CameraStatusIt = () => {
  return (
    <>
      <GlobalAllCamera ItDashboard={true} />
    </>
  )
}

export default CameraStatusIt